import _defineProperty from "/usr/src/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import moment from 'moment-timezone';
import { clamp } from "./MathUtils";
import { getTranslationOrOther, translateArray } from "./TranslationUtils";
import Big from 'big.js';
import { ChargeCalculationBasis, ContactInfoSource, DOCUMENT_TYPE, FINNISH_DATE_FORMAT, HousingCooperativeMortgageType, IMAGE_URL, IS_AWARE, LISTING_STATUS, LISTING_TYPE, LOT_OWNERSHIP, PRESENTATION_TYPE, PRODUCT_GROUP, PUBLIC_PRESENTATION_TYPES, SERVICE_PACKAGE, VideoType } from "./DixuConstants";
import { isStorybookEnv } from "./EnvUtils";
import { formatNumber, formatPrice, parseNumber } from "./MoneyUtils";
import { translateArrayWithHumanize } from "./TranslationUtils";
import { DefaultTimeFormat, timestampToDay } from "./DateTimeUtils";
export const isDixuPlus = listing => listing?.servicePackage === SERVICE_PACKAGE.DIXU_PLUS;
export const isDixuEasy = listing => listing?.servicePackage === SERVICE_PACKAGE.DIXU_EASY;
export const canCreatePurchaseOffer = listing => {
  const {
    type,
    productGroup,
    status,
    servicePackage
  } = listing || {};
  if (type === LISTING_TYPE.PROPERTY) {
    return false;
  }
  if (productGroup === PRODUCT_GROUP.NEWLY_CONSTRUCTED) {
    return false;
  }
  if (status === LISTING_STATUS.ON_SALE) {
    // Realtors own listing
    if (!servicePackage) {
      return true;
    }
    if (servicePackage === SERVICE_PACKAGE.DIXU_PLUS) {
      return true;
    }
    if (servicePackage === SERVICE_PACKAGE.DIXU_EASY && type === LISTING_TYPE.APARTMENT) {
      return true;
    }
  }
  return false;
};
export const isPublished = listing => listing && ![LISTING_STATUS.SERVICE_AGREEMENT_SIGNED, LISTING_STATUS.READY_TO_PUBLISH, LISTING_STATUS.INSPECTION].includes(listing.status);
export const getPostalCodeDetails = listing => {
  const {
    postalCode,
    districtFree,
    city,
    municipality
  } = listing || {};
  return [postalCode, [city, municipality].includes(districtFree) ? null : districtFree, [municipality].includes(city) ? null : city, municipality].filter(Boolean).join(', ');
};
export const getAddress = listing => {
  const {
    address,
    gate,
    apartmentNumber
  } = listing || {};
  return [address, gate, apartmentNumber].filter(Boolean).join(' ');
};
export const getStreetAddress = listing => {
  const {
    address,
    gate,
    apartmentNumber
  } = listing || {};

  // Newly constructed will have apartment number visible
  if (listing?.productGroup === PRODUCT_GROUP.NEWLY_CONSTRUCTED) {
    return [address, gate, apartmentNumber].filter(Boolean).join(' ');
  }
  return [address, gate].filter(Boolean).join(' ');
};
export const getFullStreetAddress = listing => {
  return [getStreetAddress(listing), getPostalCodeDetails(listing)].filter(Boolean).join(', ');
};
export const getFullAddress = listing => {
  return [getAddress(listing), getPostalCodeDetails(listing)].filter(Boolean).join(', ');
};
export const getTotalViews = listing => {
  const {
    views
  } = listing || {};
  return views?.reduce((acc, cur) => {
    const {
      views: viewCount
    } = cur;
    return acc + Number(viewCount);
  }, 0);
};
export const getNextPresentation = _ref => {
  let {
    listing,
    publicOnly = true,
    t
  } = _ref;
  if (!listing.presentations?.length) {
    return null;
  }
  const sortedPresentations = listing.presentations.filter(presentation => publicOnly ? PUBLIC_PRESENTATION_TYPES.includes(presentation.type) : presentation.type !== PRESENTATION_TYPE.AVAILABLE_SLOT).map(presentation => {
    const dateTime = moment(`${presentation.date} ${presentation.startTime}`);
    return _objectSpread(_objectSpread({}, presentation), {}, {
      presentationMoment: dateTime
    });
  }).filter(presentation => {
    return presentation.presentationMoment > moment().tz('Europe/Helsinki');
  }).sort((a, b) => a.presentationMoment - b.presentationMoment);
  if (sortedPresentations && sortedPresentations.length > 0) {
    const firstPresentation = sortedPresentations[0];
    const startDateTime = moment(`${firstPresentation.date} ${firstPresentation.startTime}`);
    const endDateTime = moment(`${firstPresentation.date} ${firstPresentation.endTime}`);
    const firstShowingText = firstPresentation.type === PRESENTATION_TYPE.FIRST_SHOWING ? `${t('listing:FirstShowing')} ` : '';
    return `${firstShowingText}${moment(startDateTime).format(FINNISH_DATE_FORMAT)} klo ${moment(startDateTime).format(DefaultTimeFormat)} - ${moment(endDateTime).format(DefaultTimeFormat)}`;
  }
  return null;
};
export const getConstructionMaterials = (listing, _ref2) => {
  let {
    t,
    i18n
  } = _ref2;
  if (!listing) {
    return null;
  }
  const hasMaterials = listing?.constructionMaterial?.length > 0;
  if (hasMaterials) {
    return translateArray(listing.constructionMaterial, 'listing:MaterialTypes.', {
      t,
      i18n
    });
  }
  return null;
};
export const getHeatingSystem = (listing, t, i18n) => {
  const getElectricDescription = () => {
    return [t(`listing:PropertyHeatingTypes.ELECTRIC`), listing.electricHeatingType && `${t('common:Type')}: ${listing.electricHeatingType}`, listing.electricHeatingPowerUsage && `${t('common:Expenses')}: ${listing.electricHeatingPowerUsage} kWh / vuosi`].filter(Boolean).join('\n');
  };
  const getHeatingSystemDescriptions = () => {
    return listing.heatingSystem?.map(system => {
      if (system === 'ELECTRIC') return getElectricDescription();
      return getTranslationOrOther(system, 'listing:PropertyHeatingTypes.', {
        t,
        i18n
      });
    });
  };
  const descriptions = getHeatingSystemDescriptions();
  return descriptions?.filter(Boolean).join('\n');
};
const VALID_ROOF_TYPES = ['FLAT', 'GABLED', 'PENTROOF'];
export const getRoofType = (listing, t) => listing && listing.roofType ? VALID_ROOF_TYPES.includes(listing.roofType) ? t(`listing:PropertyRoofTypes.${listing.roofType}`) : listing.roofType : null;
export const getPricePerSquareMeter = function (listing) {
  let price = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : listing?.debtFreePrice;
  return parseNumber(parseNumber(price) / parseNumber(listing?.area));
};
export const getChargePerText = _ref3 => {
  let {
    charge,
    calculationBasis,
    area,
    shareCount
  } = _ref3;
  if (!charge || !calculationBasis) {
    return null;
  }
  if (calculationBasis === ChargeCalculationBasis.AREA) {
    if (!area) {
      return null;
    }
    const amount = new Big(charge).div(area).toFixed(2);
    return `(${formatPrice(amount, {
      suffix: ' / m²'
    })})`;
  }
  if (calculationBasis === ChargeCalculationBasis.SHARE_COUNT) {
    if (!shareCount) {
      return null;
    }
    const amount = new Big(charge).div(shareCount).toFixed(2);
    return `(${formatPrice(amount, {
      suffix: ' / os.'
    })})`;
  }
  return `(${calculationBasis})`;
};
export const getMaintenanceCharge = (listing, t) => {
  const {
    maintenanceCharge,
    mandatoryCharges,
    fundingCharge,
    renovationCharge,
    plotRentCharge,
    otherCharge,
    mandatoryChargesCalculationBasis,
    fundingChargeCalculationBasis,
    renovationChargeCalculationBasis,
    plotRentChargeCalculationBasis,
    otherChargeCalculationBasis,
    area,
    shareCount
  } = listing || {};
  const charges = [];
  if (mandatoryCharges) {
    charges.push([t('listing:ListingDetails.MaintenanceChargeSeparated.MandatoryCharges', {
      value: formatPrice(mandatoryCharges)
    }), getChargePerText({
      charge: mandatoryCharges,
      calculationBasis: mandatoryChargesCalculationBasis,
      area,
      shareCount
    })].filter(Boolean).join(' '));
  }
  if (fundingCharge) {
    charges.push([t('listing:ListingDetails.MaintenanceChargeSeparated.FundingCharge', {
      value: formatPrice(fundingCharge)
    }), getChargePerText({
      charge: fundingCharge,
      calculationBasis: fundingChargeCalculationBasis,
      area,
      shareCount
    })].filter(Boolean).join(' '));
  }
  if (renovationCharge) {
    charges.push([t('listing:ListingDetails.MaintenanceChargeSeparated.RenovationCharge', {
      value: formatPrice(renovationCharge)
    }), getChargePerText({
      charge: renovationCharge,
      calculationBasis: renovationChargeCalculationBasis,
      area,
      shareCount
    })].filter(Boolean).join(' '));
  }
  if (plotRentCharge) {
    charges.push([t('listing:ListingDetails.MaintenanceChargeSeparated.PlotRentCharge', {
      value: formatPrice(plotRentCharge)
    }), getChargePerText({
      charge: plotRentCharge,
      calculationBasis: plotRentChargeCalculationBasis,
      area,
      shareCount
    })].filter(Boolean).join(' '));
  }
  if (otherCharge) {
    charges.push([t('listing:ListingDetails.MaintenanceChargeSeparated.OtherCharge', {
      value: formatPrice(otherCharge)
    }), getChargePerText({
      charge: otherCharge,
      calculationBasis: otherChargeCalculationBasis,
      area,
      shareCount
    })].filter(Boolean).join(' '));
  }
  if (!maintenanceCharge) {
    return charges.join(',\n');
  }
  const maintenanceChargeText = t('listing:ListingDetails.MaintenanceChargeSeparated.MaintenanceCharge', {
    value: formatPrice(maintenanceCharge)
  });
  return [...charges, maintenanceChargeText].join(',\n');
};
export const getFireplaceType = _ref4 => {
  let {
    listing,
    t,
    i18n
  } = _ref4;
  if (!listing || !listing.fireplace) return null;
  return translateArrayWithHumanize(listing.fireplace, 'listing:FirePlace.', {
    t,
    i18n
  });
};
export const getAddressUrlFormat = listing => {
  const {
    address,
    city,
    municipality
  } = listing || {};
  if (!address) {
    return undefined;
  }
  return encodeURIComponent([address, [municipality].includes(city) ? null : city, municipality].filter(Boolean).join('-').replace(/[;,/?:@&=+$#]+/g, '').replace(/\s+/g, '-').replace(/-{2,}/g, '-').toLowerCase());
};
export const isSold = listing => listing?.status === LISTING_STATUS.SOLD;
export const isRealtorListing = listing => listing?.servicePackage !== SERVICE_PACKAGE.DIXU_EASY;
export const getPrimarySellerIndex = listing => listing.sellers.findIndex(seller => seller.isPrimary);
export const getCommissionTask = (commission, user) => {
  return commission?.signicatCommissionTasks?.find(task => task.signer.toString() === user?.id.toString());
};
export const getDescriptionAttachmentTask = (listing, user) => {
  return listing?.signicatDescriptionAttachmentTasks?.find(task => task.signer === user.id.toString());
};
export const userHasNotSignedCommission = (commission, user) => {
  const signingTask = getCommissionTask(commission, user);
  return commission?.signicatDocumentId && commission?.signicatCommissionTasks && signingTask?.status !== 'COMPLETED';
};
export const userHasNotSignedDescriptionAttachment = (listing, user) => {
  const signingTask = getDescriptionAttachmentTask(listing, user);
  return listing?.signicatDescriptionAttachmentDocumentId && listing?.signicatDescriptionAttachmentTasks && signingTask?.status !== 'COMPLETED';
};
export const userIsDescriptionAttachmentSigner = (listing, user) => {
  return listing.signicatDescriptionAttachmentTasks.find(task => task.signer === user.id.toString());
};
export const userIsCommissionSigner = (commission, user) => {
  return commission.signicatCommissionTasks.find(task => task.signer === user.id.toString());
};
export const commissionIsSentForSigning = commission => !!commission && commission?.signicatCommissionId;
export const descriptionAttachmentIsSentForSigning = listing => !!listing && listing?.signicatDescriptionAttachmentId;
export const serviceAgreementIsSentForSigning = listing => listing?.signicatServiceAgreementId;
export const hasMultipleSellers = model => model?.sellers?.length > 1;
export const hasRealtor = listing => !!listing.realtor;
export const getImageUrl = image => {
  if (isStorybookEnv || image?.includes(IMAGE_URL)) {
    return image;
  }
  return `${IMAGE_URL}${image}`;
};
export const hasCancellationForm = commission => {
  if (!commission) {
    return null;
  }
  if (commission && !commission.signedInRealtorProperty && commission.startSellingBeforeCancellationTime) {
    return false;
  }
  return true;
};
export const canDownloadDocuments = listing => {
  const {
    status
  } = listing || {};
  return [LISTING_STATUS.ON_SALE, LISTING_STATUS.WAITING_FOR_BUYER_SIGNATURES, LISTING_STATUS.WAITING_FOR_SELLER_SIGNATURES].includes(status);
};
export const listingNeedsPepForPurchaseOffers = listing => {
  if (!listing) {
    return false;
  }
  return !isDixuEasy(listing) && hasRealtor(listing);
};
export const getAreaBasis = (listing, _ref5) => {
  let {
    t,
    i18n
  } = _ref5;
  const {
    areaBasis,
    areaBasisControlMeasured,
    areaBasisMeasuringCompany,
    areaBasisMeasuringPerson,
    areaBasisMeasuringDate
  } = listing || {};
  return [areaBasis && translateArray(areaBasis, 'listing:AreaBasisType.', {
    t,
    i18n
  }), [areaBasisControlMeasured != null && areaBasis && '(', areaBasisControlMeasured && t('listing:AreaBasisDetails.ControlMeasurementDetails', {
    areaBasisMeasuringCompany,
    areaBasisMeasuringPerson,
    areaBasisMeasuringDate: areaBasisMeasuringDate ? moment(areaBasisMeasuringDate).format(FINNISH_DATE_FORMAT) : ''
  }), areaBasisControlMeasured === false && t('listing:AreaBasisDetails.ControlMeasurementNotMeasuredDetails'), areaBasisControlMeasured != null && areaBasis && ')'].filter(Boolean).join('').replace(/\s{2,}/, ' ')].filter(Boolean).join(', ');
};
export const getPaginationData = function () {
  let {
    requestedPage,
    totalListingCount,
    listingsPerPage
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const lastPage = Math.ceil(totalListingCount / listingsPerPage) || 1;
  const currentPage = clamp(requestedPage, 1, lastPage);
  return {
    totalListingCount: totalListingCount || 0,
    currentPage: currentPage || 1,
    pageSize: listingsPerPage || 1
  };
};
export const getListingURL = function (listing) {
  let {
    service = 'dixu',
    modifier
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (service === 'dixu') {
    const {
      id,
      status
    } = listing || {};
    const addressUrlFormat = getAddressUrlFormat(listing);
    if (modifier === 'bidding') {
      return `/tarjouskauppa/${addressUrlFormat}/${id}`;
    }
    if ([LISTING_STATUS.READY_TO_PUBLISH].includes(status)) {
      return `/myytavat-asunnot/asunto/${addressUrlFormat}/${id}?preview=true`;
    }
    return `/myytavat-asunnot/asunto/${addressUrlFormat}/${id}`;
  }
  return undefined;
};
export const getListingDocumentID = function () {
  let {
    listing,
    type,
    status
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!listing || !type || !status) {
    return undefined;
  }
  if (type === DOCUMENT_TYPE.COMMISSION) {
    const {
      commission
    } = listing || {};
    const {
      signedDocuments
    } = commission || {};
    return signedDocuments?.find(doc => {
      const {
        status: docStatus
      } = doc || {};
      return docStatus === status;
    })?.id;
  }
  return undefined;
};
export const isPlot = listing => listing?.productGroup === PRODUCT_GROUP.PLOTS;
export const getDisplayedArea = listing => {
  if (isPlot(listing)) {
    return listing?.lotArea;
  }
  return listing?.area;
};
export const getContactRequestTel = listing => {
  const {
    contactTelIsPublic,
    contactTel,
    selfSeller,
    user,
    clientSchemas
  } = listing || {};
  if (listing.contactInfoSource === ContactInfoSource.OFFICE) {
    return listing.realtor.companyTel;
  }
  if (!listing || !isDixuEasy(listing) || !contactTelIsPublic) {
    return null;
  }
  const primarySeller = clientSchemas?.find(seller => seller.isPrimary) || clientSchemas?.[0];
  return contactTel || selfSeller?.tel || primarySeller?.tel || user?.tel || null;
};
export const getVideoUrl = listing => {
  if (listing.videoUrl && listing.droneUrl) {
    if (listing.selectedVideoType === VideoType.VIDEO) return listing.videoUrl;
    if (listing.selectedVideoType === VideoType.DRONE) return listing.droneUrl;
    return null;
  }
  if (listing.videoUrl) return listing.videoUrl;
  if (listing.droneUrl) return listing.droneUrl;
  return null;
};
export const getFloors = listing => {
  if (!listing) {
    return null;
  }
  const {
    type,
    floor,
    floorCount
  } = listing;
  if (!type || type === LISTING_TYPE.APARTMENT && (floor != null || floorCount != null)) {
    return `${floor == null ? '-' : floor} / ${floorCount == null ? '-' : floorCount}`;
  }
  if (type === LISTING_TYPE.PROPERTY) {
    return floorCount;
  }
  return null;
};
export const getDealIncludes = (listing, t) => {
  if (!listing?.firewoodIncluded && !listing?.dealIncludes) {
    return null;
  }
  const firewoodIncluded = listing?.firewoodIncluded && t('listing:ListingDetails.FirewoodIncluded');
  return [listing.dealIncludes, firewoodIncluded].filter(Boolean).join('\n');
};
export const getDealDoesNotInclude = (listing, t) => {
  if (listing?.firewoodIncluded === null && !listing?.dealDoesNotInclude) {
    return null;
  }
  const firewoodNotIncluded = listing.firewoodIncluded === false && t('listing:ListingDetails.FirewoodNotIncluded');
  return [listing.dealDoesNotInclude, firewoodNotIncluded].filter(Boolean).join('\n');
};
export const getEquipmentAndAdditionalDetails = function (equipments, details) {
  let {
    translationKey,
    t,
    i18n
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const filteredEquipments = equipments?.filter(Boolean);
  if (!filteredEquipments?.length) {
    return null;
  }
  const data = filteredEquipments.map((equipment, index) => {
    const detail = details?.[equipment];
    if (detail == null || detail === '') {
      return equipment;
    }
    const equipmentTranslation = getTranslationOrOther(equipment, translationKey, {
      i18n,
      t
    });
    const translatedEquipment = (() => {
      if (equipmentTranslation) {
        if (index === 0) {
          return equipmentTranslation;
        }
        return equipmentTranslation.toLowerCase();
      }
      return equipment;
    })();
    return `${translatedEquipment} (${detail})`;
  });
  return translateArrayWithHumanize(data, translationKey, {
    t,
    i18n
  });
};
export const getOtherFees = _ref6 => {
  let {
    listing,
    t
  } = _ref6;
  const otherFees = [listing.chargeInfo];
  if (listing.canHousingCoopChargeExtraCompensation && listing.type === LISTING_TYPE.APARTMENT) {
    otherFees.push(t('listing:ListingDetails.CanHousingCoopChargeExtraCompensation'));
  }
  return otherFees.filter(Boolean).join('\n\n');
};
export const getFlawsAndDamages = _ref7 => {
  let {
    listing,
    i18n,
    t
  } = _ref7;
  const {
    sellerAwareWaterDamage,
    sellerAwareHumidityDamage,
    sellerAwareMoldDamage,
    sellerAwareWaterDamageLocation,
    sellerAwareHumidityDamageLocation,
    sellerAwareMoldDamageLocation
  } = listing;
  return [sellerAwareWaterDamage && `${t('listing:ListingDetails.SellerAwareWaterDamage')}: ${t(`listing:ListingDetails.IsAware.${sellerAwareWaterDamage}`)}\n`, sellerAwareWaterDamageLocation && sellerAwareWaterDamage === IS_AWARE.YES && `${t('listing:ListingDetails.DamageHasHappened')}: ${translateArrayWithHumanize(sellerAwareWaterDamageLocation, 'listing:ListingDetails.DamageLocation.', {
    t,
    i18n
  })}\n`, (listing?.sellerAwareWaterDamage || listing.sellerAwareWaterDamageLocation) && '\n', sellerAwareHumidityDamage && `${t('listing:ListingDetails.SellerAwareHumidityDamage')}: ${t(`listing:ListingDetails.IsAware.${sellerAwareHumidityDamage}`)}\n`, sellerAwareHumidityDamageLocation && sellerAwareHumidityDamage === IS_AWARE.YES && `${t('listing:ListingDetails.DamageHasHappened')}: ${translateArrayWithHumanize(sellerAwareHumidityDamageLocation, 'listing:ListingDetails.DamageLocation.', {
    t,
    i18n
  })}\n`, (listing?.sellerAwareHumidityDamage || listing.sellerAwareHumidityDamageLocation) && '\n', sellerAwareMoldDamage && `${t('listing:ListingDetails.SellerAwareMoldDamage')}: ${t(`listing:ListingDetails.IsAware.${sellerAwareMoldDamage}`)}\n`, sellerAwareMoldDamageLocation && sellerAwareMoldDamage === IS_AWARE.YES && `${t('listing:ListingDetails.DamageHasHappened')}: ${translateArrayWithHumanize(sellerAwareMoldDamageLocation, 'listing:ListingDetails.DamageLocation.', {
    t,
    i18n
  })}\n`].filter(Boolean).join('').trimRight('\n');
};
export const getBalconyDescription = _ref8 => {
  let {
    listing,
    t,
    i18n
  } = _ref8;
  if (listing.hasBalcony == null) return null;
  if (listing.hasBalcony === false) return t('common:BalconyTypes.NoBalcony');
  if (listing.hasBalcony && !listing.balconyTypes?.length) return t('common:BalconyTypes.HasBalcony');
  return translateArrayWithHumanize(listing.balconyTypes, 'common:BalconyTypes.', {
    t,
    i18n
  });
};
export const isLotOwnershipRentOrOptional = lotOwnership => lotOwnership === LOT_OWNERSHIP.RENT || lotOwnership === LOT_OWNERSHIP.OPTIONAL;
export const getLocation = listing => {
  if (!listing) {
    return null;
  }
  const {
    city,
    municipality,
    districtFree
  } = listing;
  return [[city, municipality].map(e => e?.trim().toLowerCase()).includes(districtFree?.toLowerCase()) ? null : districtFree?.trim(), municipality ? null : city?.trim(), municipality?.trim()].filter(Boolean).join(', ');
};
export const getDisplayPrice = listing => {
  if (!listing) {
    return null;
  }
  const {
    bidding,
    biddingData,
    debtFreePrice
  } = listing;
  const {
    latestOfferPrice,
    debtlessStartPrice
  } = biddingData || {};
  if (bidding || biddingData) {
    return formatPrice(latestOfferPrice || debtlessStartPrice);
  }
  return formatPrice(debtFreePrice);
};
export const getHousingCooperativeMortgageDate = _ref9 => {
  let {
    listing,
    t
  } = _ref9;
  if (listing.housingCooperativeMortgageType === HousingCooperativeMortgageType.PROPERTY_MANAGER_CERTIFICATE_DATE) {
    return t('listing:ListingDetails.HousingCooperativeMortgageDateValues.PROPERTY_MANAGER_CERTIFICATE_DATE');
  }
  if (listing.housingCooperativeMortgageType === HousingCooperativeMortgageType.DATE && listing.housingCooperativeMortgageDate) {
    return timestampToDay(listing.housingCooperativeMortgageDate);
  }
  return null;
};
export const getLotArea = _ref10 => {
  let {
    listing,
    t
  } = _ref10;
  if (!listing) {
    return null;
  }
  if (!listing.lotAreaUnit) {
    return null;
  }
  if (listing.lotArea == null || listing.lotArea === '') {
    return null;
  }
  const lotAreaFormatted = formatNumber(listing.lotArea);
  const lotAreaUnit = t(`listing:LotAreaUnit.${listing.lotAreaUnit}`);
  return `${lotAreaFormatted} ${lotAreaUnit}`;
};
export const isListingProperty = _ref11 => {
  let {
    listing
  } = _ref11;
  return listing?.type === LISTING_TYPE.PROPERTY;
};